import React from "react";
import { Helmet } from "react-helmet"
import { DOMAIN } from '../../config';

const Seoinfo = (props) => (

  <Helmet>
    <meta charSet="utf-8" />
    <title>{props.titlePage}</title>
    <meta name="description" content={props.description} />
    <meta name="keywords" content={props.keywords} />
    <meta property="og:type" content={props.type} />
    <meta property="og:title" content={props.titlePage} />
    <meta property="og:url" content={`${DOMAIN}${props.url}`} />
    <meta property="og:image" content={`${DOMAIN}${props.imageFacebook}`} />
    <meta property="og:description" content={props.description} />
    <meta property="og:site_name" content={props.siteName} />
  </Helmet>
);
export default Seoinfo;



